<template>
  <div class="relative">
    <div v-if="isLocal" class="w-full h-full">
      <div v-if="pending" class="w-full h-full absolute left-0 right-0 flex items-center justify-center">
        <div class="flex flex-col items-center">
          <div class="flex flex-row items-center">
            <ProgressCircle :value="progress" />
            <span class="text-sm px-2">{{ $t('Vendor portfolio.Loading') }}</span>
            <span class="text-xs text-tertiary">{{ progress }}%</span>
          </div>
        </div>
      </div>
      <template v-else>
        <span class="text-xs text-secondary select-none">
          {{ value.original_name | uppercase }}
        </span>
      </template>
    </div>
    <div v-else-if="value" class="w-full h-full">
      <span class="text-xs text-secondary select-none">
        {{ value.original_name | uppercase }}
      </span>
    </div>
    <slot />
  </div>
</template>

<script>
import { postUploadAttachment } from '@/api/vendors/portfolio';
import ProgressCircle from '@/components/ProgressCircle.vue';
import { devotedNotify } from '@/notify';

export default {
  name: 'Attachment',
  components: {
    ProgressCircle,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    uploadOnMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preview: null,
      pending: false,
      progress: 0,
    };
  },
  computed: {
    isLocal() {
      return !!this.value?.file;
    },
  },
  mounted() {
    if (this.value.file) {
      this.preview = URL.createObjectURL(this.value.file);
      if (this.uploadOnMounted) {
        this.pending = true;
        const fd = new FormData();
        fd.append('file', this.value.file);
        postUploadAttachment(fd, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (percentCompleted) {
              this.progress = percentCompleted;
            }
          },
        })
          .then(({ data }) => {
            this.progress = 0;
            this.$emit('loaded', data);
          })
          .catch(() => {
            devotedNotify.error({
              text: this.$t('Attachment.Error on attachment upload'),
            });
            this.$emit('load-error');
          });
      }
    }
  },
};
</script>
